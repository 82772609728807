<style lang="scss" scoped>
p {
    /*line-height: 24px;*/
    span {
        color: #909399;
        display: inline-block;

        &:first-child {
            width: 85px;
        }
    }
}

.list-content-fourth {
    width: calc(100% - 952px);
    height: 100%;
    padding: 0 10px;
    font-size: 12px;

    p {
        line-height: 18px;
    }

    .fourth-top {
        /*height: 105px;*/
        /*padding: 10px;*/
        margin-bottom: 20px;
    }

    .fourth-bottom {
        margin-bottom: 20px;
        /*height: 112px;*/
        /*padding: 10px;*/
    }
}

.item-bar {
    display: flex;
}

.item-bar-label {
    min-width: 85px;
    color: #909399;
}
</style>
<template>

    <div class="list-content-fourth">
        <div class="fourth-top" v-if="userInfo.userType!=1">
            <div v-if="orderType !== 'insuranceList'">
                <p style="font-size: 12px;line-height: 18px"><span
                    style="color: #333">运营商: </span>{{ orderInfo.operatorName }}
                </p>
                <p>
                    <span>销售单价：</span>
                    <!--                    {{-->
                    <!--                        ((orderInfo.sourceType == 9 || orderInfo.sourceType == 10) ?-->
                    <!--                            orderInfo.inventoryCustomerPrice : orderInfo.inventoryPeerPrice) | currency-->
                    <!--                    }}-->
                    <edit-price-dialog
                        :value="(orderInfo.sourceType == 9 || orderInfo.sourceType == 10) ?
                            orderInfo.inventoryCustomerPrice : orderInfo.inventoryPeerPrice"
                        :id="orderInfo.orderId"
                        label="销售单价" value-key="inventoryPeerPrice"/>
                </p>
                <p><span>销售数量：</span>{{ orderInfo.resourceNumber || 0 }}</p>
            </div>

            <p><span>订单金额：</span>
                <!--                {{ orderInfo.orderAmount | currency }}-->
                <edit-price-dialog :value="orderInfo.orderAmount"
                                   :id="orderInfo.orderId"
                                   label="订单金额"
                                   value-key="orderAmount"/>
            </p>
            <p><span>平台费：</span>{{ orderInfo.platformAmount | currency }}</p>
            <div v-if="orderType !== 'insuranceList'">
                <p v-if="userInfo.userType==0">
                    <span>优惠：</span>
                    {{ orderInfo.discountsAmount | currency }}
                    <!--                  <span>{{ orderInfo.discountsInfo && orderInfo.discountsInfo.info }}</span>  -->
                </p>
                <p><span>渠道费：</span>{{ orderInfo.channelAmount | currency }}</p>
                <p><span>渠道调价金额：</span>{{ orderInfo.adjustAmount | currency }}</p>
                <p><span>已退金额：</span>{{ orderInfo.refundAmount | currency }}</p>
                <p v-if="userInfo.userType == 0"><span>取消费：</span>{{ orderInfo.cancelAmount | currency }}</p>
                <p style="font-weight: 600;font-size: 14px">
                    <span style="color: #333;">应收金额：</span><span
                    style="color: #ff3b30"> {{ orderInfo.receivableAmount | currency }}</span>
                </p>
                <p><span>实收金额：</span>{{ orderInfo.paymentAmount | currency }}</p>
                <p v-if="orderInfo.tripRebateDto">
                    <span>返佣金额:</span>{{ orderInfo.tripRebateDto.rebatePrice | currency }}</p>
                <p v-if="orderInfo.orderAdditionalPriceDto" style="display: flex;align-items: center">
                    <span>补款应收：</span>
                    {{ (orderInfo.orderAdditionalPriceDto.totalAdditionalFee || 0)|currency }}
                    <el-popover placement="left-start" size="mini" width="200" trigger="click">
                        <div>
                            <p>
                                <span>超公里费：</span>
                                {{ (orderInfo.orderAdditionalPriceDto.overDistanceFee || 0)|currency }}元
                            </p>
                            <p>
                                <span>超时长费：</span>
                                {{ (orderInfo.orderAdditionalPriceDto.overTimeFee || 0)|currency }}元
                            </p>
                            <p>
                                <span>其他费用：</span>
                                {{ (orderInfo.orderAdditionalPriceDto.otherFee || 0)|currency }}元
                            </p>
                        </div>
                        <el-button size="mini" type="text" style="margin-left: 10px;" slot="reference">
                            费用明细 >
                        </el-button>
                    </el-popover>
                </p>
                <p v-if="orderInfo.orderAdditionalPriceDto"><span>补款实收：</span>
                    {{
                        (orderInfo.orderAdditionalPriceDto.status === 1 ? orderInfo.orderAdditionalPriceDto.totalAdditionalFee : 0) |currency
                    }}
                </p>
                <!--                <p v-if="orderInfo.orderAdditionalPriceDto"><span>最终应收：</span>{{-->
                <!--                        (orderInfo.orderAdditionalPriceDto.totalAdditionalFee + orderInfo.receivableAmount) | currency-->
                <!--                    }}</p>-->
                <div class="finance-confirm"
                     v-if="userInfo.roleName === '总部财务' && ( orderInfo.orderStatus === 61 || orderInfo.cancelStatus === 2||
                     (type==='trip'&&orderInfo.orderStatus===41))">
                    <el-input placeholder="请输入内容" v-model="form.paymentAmount"
                              @change="budgetPerChange(1, form.paymentAmount)">
                        <el-button slot="append"
                                   @click="updatePayAmountByOrder(orderInfo.orderId, form.paymentAmount)">
                            确认
                        </el-button>
                    </el-input>
                </div>
            </div>
        </div>
        <div class="fourth-bottom">
            <div v-if="orderType !== 'insuranceList'">
                <p style="font-size: 12px;line-height: 18px"><span
                    style="color: #333">供应商: </span>{{ orderInfo.supplierName || '暂未分配供应商' }}
                </p>
                <p><span>结算单价: </span>{{ orderInfo.inventorySettlePrice | currency }}</p>
                <p><span>结算数量：</span>{{ orderInfo.resourceNumber || 0 }}</p>
                <p style="font-weight: 600;font-size: 14px">
                    <span
                        style="color: #333;">{{ userInfo.userType == 1 ? '应收金额：' : '应付金额：' }}</span>{{
                        orderInfo.settleAmount
                            | currency
                    }}</p>
                <p>
                    <span>{{ userInfo.userType == 1 ? '实收金额：' : '实付金额：' }}</span>{{
                        orderInfo.actualSettleAmount
                            | currency
                    }}
                </p>
                <p v-if="orderInfo.orderAdditionalPriceDto" style="display: flex;align-items: center">
                    <span>补款应收：</span>
                    {{ (orderInfo.orderAdditionalPriceDto.totalAdditionalFee || 0)|currency }}
                    <el-popover placement="left-start" size="mini" width="200" trigger="click">
                        <div>
                            <p>
                                <span>超公里费：</span>
                                {{ (orderInfo.orderAdditionalPriceDto.overDistanceFee || 0)|currency }}元
                            </p>
                            <p>
                                <span>超时长费：</span>
                                {{ (orderInfo.orderAdditionalPriceDto.overTimeFee || 0)|currency }}元
                            </p>
                            <p>
                                <span>其他费用：</span>
                                {{ (orderInfo.orderAdditionalPriceDto.otherFee || 0)|currency }}元
                            </p>
                        </div>
                        <el-button size="mini" type="text" style="margin-left: 10px;" slot="reference">
                            费用明细 >
                        </el-button>
                    </el-popover>
                </p>
                <p v-if="orderInfo.orderAdditionalPriceDto">
                    <span>补款实收：</span>
                    {{
                        (orderInfo.orderAdditionalPriceDto.status === 1 ? orderInfo.orderAdditionalPriceDto.totalAdditionalFee : 0) |currency
                    }}
                </p>
                <div class="finance-confirm"
                     v-if="userInfo.roleName === '总部财务' && (orderInfo.orderStatus === 61 || orderInfo.cancelStatus === 2||(type==='trip'&&orderInfo.orderStatus===41))">
                    <!--                <div class="finance-confirm">-->
                    <el-input placeholder="请输入内容" v-model="form.actualSettleAmount"
                              @change="budgetPerChange(2, form.actualSettleAmount)">
                        <el-button slot="append"
                                   @click="updateActualSettleAmount(orderInfo.orderId, form.actualSettleAmount)">
                            确认
                        </el-button>
                    </el-input>
                </div>
            </div>
            <p style="font-weight: 600;padding-top: 10px;font-size: 14px"><span
                style="color: #333;width: 100px">司机结算金额：</span>{{
                    orderInfo.driverPrice
                        | currency
                }}</p>
            <p v-if="orderInfo.isHighSpeedPriority===1"><span
                style="width: 100px">已包含高速费用：</span>{{ orderInfo.tollFee | currency }}
            </p>
            <p style="color:#319bf7;padding-top: 10px;font-size: 14px;cursor: pointer"
               v-if="orderInfo.sourceType === 18 && orderType !== 'insuranceList'"
               @click="handleCTripCost(orderInfoIndex)">携程包车额外费用明细></p>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import {keepTwoDecimal} from '@/common/js/Utils';
import EditPriceDialog from './edit-price-dialog'

export default {
    data() {
        return {
            form: {
                paymentAmount: 0,
                actualSettleAmount: 0
            }
        }
    },
    props: {
        info: {
            type: Object,
            default: function () {
                return {}
            }
        },
        userInfo: {
            type: Object,
            default: function () {
                return {};
            }
        },
        orderType: {
            type: String,
            default: null
        },
        orderInfoIndex: {
            type: Number,
            default: null
        },
        type: {
            type: String,
            default: 'userCar'
        }
    },
    components: {EditPriceDialog},
    mounted() {
        this.form.paymentAmount = this.orderInfo.paymentAmount
        this.form.actualSettleAmount = this.orderInfo.actualSettleAmount
    },
    computed: {
        orderInfo() {
            const orderInfo = {...this.info};
            if (orderInfo.discountsInfo)
                orderInfo.discountsInfo = JSON.parse(orderInfo.discountsInfo)
            return orderInfo;
        }
    },
    //定义事件方法
    methods: {
        budgetPerChange(type, value) {
            switch (type) {
                case 1:
                    this.orderInfo.paymentAmount = keepTwoDecimal(value);
                    break;
                case 2:
                    this.orderInfo.actualSettleAmount = keepTwoDecimal(value);
                    break;
            }
        }, // 人均价格// 金额小数点格式化
        async updatePayAmountByOrder(orderId, paymentAmount) {
            let form = {
                id: orderId,
                paymentAmount: paymentAmount
            }
            let res = await this.http('/galaxyOrder/updatePayAmountByOrderId', form, 'POST');
            if (res.success && res.data) {
                this.$message({
                    message: '订单实收金额更新成功',
                    type: 'success'
                });
                this.$emit('updateOrderList')
            }

        }, // 更新订单实收金额
        async updateActualSettleAmount(orderId, actualSettleAmount) {
            let form = {
                id: orderId,
                actualSettleAmount: actualSettleAmount,
            }
            let res = await this.http('/galaxyOrder/updateActualSettleAmount', form, 'POST');
            if (res.success && res.data) {
                this.$message({
                    message: '订单实付金额更新成功',
                    type: 'success'
                });
                this.$emit('updateOrderList')

            }

        }, // 更新订单实付金额

        handleCTripCost(orderInfoIndex) {
            this.$emit('updateCTripCost', orderInfoIndex)
        }, // 携程包车额外费用明细
    },
    watch: {
        orderInfo() {
            this.form.paymentAmount = this.orderInfo.paymentAmount
            this.form.actualSettleAmount = this.orderInfo.actualSettleAmount
        }
    }

}
</script>

