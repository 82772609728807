<style lang="scss" scoped>
</style>
<template>
    <el-popover placement="right" width="700" trigger="click" size="mini" title="全部出行人">
        <el-table :data="list" max-height="500">
            <el-table-column property="tripUserName" label="名称"></el-table-column>
            <el-table-column property="tripUserMobile" label="手机号" width="120"></el-table-column>
            <el-table-column property="tripUserIdNo" label="证件类型">
                <template slot-scope="scope">
                    <span v-if="scope.row.tripUserIdType">{{
                            scope.row.tripUserIdType | formatDesc(certificateList)
                        }}</span>
                    <span v-else>未填写</span>
                </template>
            </el-table-column>
            <el-table-column property="tripUserIdNo" label="证件号">
                <template slot-scope="scope">
                    {{ scope.row.tripUserIdNo || '未填写' }}
                </template>
            </el-table-column>
            <el-table-column prop="departure" label="上车地点" width="200">
                <template slot-scope="scope">
                    <p>
                        {{
                            scope.row.departure.viaPointSort === 0 ? '出发地址' : '途径' + scope.$index
                        }}上车：{{ scope.row.departure.name }}</p>
                </template>
            </el-table-column>
        </el-table>
        <el-button slot="reference" type="text" style="margin-left: 10px;height: 20px;padding: 0">全部</el-button>
    </el-popover>
</template>

<script type="text/ecmascript-6">
import {certificateList} from '@/data/index'

export default {
    data() {
        return {
            certificateList
        }
    },
    props: {
        info: {
            type: String,
            default: () => []
        },
        orderInfo: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        list() {
            try {
                const {orderViaPointDtos = []} = this.orderInfo;

                return JSON.parse(this.info).map(({
                                                      tripUserMobile,
                                                      tripUserName,
                                                      phone,
                                                      name,
                                                      tripUserIdType,
                                                      tripUserIdNo
                                                  }) =>
                    ({
                        tripUserName: tripUserName || name,
                        tripUserMobile: tripUserMobile || phone,
                        tripUserIdNo,
                        tripUserIdType,
                        departure: (orderViaPointDtos.find(({
                                                                passengerName,
                                                                passengerPhone,
                                                                pointType
                                                            }) => pointType === '1' && passengerName === tripUserName && passengerPhone === tripUserMobile) || {}) || {}
                    })
                )
            } catch (err) {
                console.log(err);
            }
            return []
        }
    }
}
</script>
